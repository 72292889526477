import React from "react";
import MarshmallowImage from "../imgs/MarshmallowCut.png";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const navigateToIntegrations = () => {
    // Navigate to the /integrations route
    navigate("/integrations");
  };
  return (
    <main className="site-content p-4">
      <div className="container-logo mx-auto p-4 flex items-center flex-row justify-center gap-6 pb-12 pt-40 max-w-[140rem]">
        <img
          className="h-20 sm:h-28 md:h-40"
          src={MarshmallowImage}
          alt="Marshmallow"
          style={{ transform: "rotate(-10deg)" }}
        />
        <div>
          <span className=" transition-all ease-in-out duration-100 items-center flex text-1xl text-marshmallow-blue justify-center tracking-[6px] sm:tracking-[10px] sm:text-3xl md:text-5xl uppercase font-bold">
            Marshmallow
          </span>
        </div>
      </div>
      <div className="flex flex-col mx-auto items-center justify-center max-w-[140rem]">
        <div className="home-sub-title text-black text-2xl text-center tracking-[2px] mt-4 uppercase font-semibold">
          The marshmallow coin of the people
        </div>
        <div className="home-description font-light mt-8 w-[90%] text-2xl text-center">
          Initially launched as an ERC20 token on Christmas Day 2023,
          MARSHMALLOW's adoption is expected to grow massively over the next
          mallownining years
        </div>
        <div className="contract-address-container pt-12 flex items-center text-center flex-col text-3xl">
          Contract Address:
          <br />
          [contract value address goes here]
        </div>
        <div className="stats-container pt-12 flex items-center text-center flex-col text-3xl gap-10">
          <div className="flex flex-col items-center border-2 border-solid border-black rounded-[10px] p-[5px_40px] bg-marshmallow-white">
            <div className="home-subtitle text-4xl tracking-wider font-bold p-1 uppercase">
              100
            </div>
            <div className="badge-title text-lg">HOLDERS</div>
          </div>
          <div className="flex flex-col items-center border-2 border-solid border-black rounded-[10px] p-[5px_40px] bg-marshmallow-white">
            <div className="home-subtitle text-4xl tracking-wider font-bold p-1 uppercase">
              1
            </div>
            <div className="badge-title text-lg">INTEGRATIONS</div>
          </div>
          <div className="flex flex-col items-center border-2 border-solid border-black rounded-[10px] p-[5px_40px] bg-marshmallow-white">
            <div className="home-subtitle text-4xl tracking-wider font-bold p-1 uppercase">
              1
            </div>
            <div className="badge-title text-lg">CHAINS</div>
          </div>
        </div>
        <div className="mission-container flex items-start flex-col mt-14 overflow-hidden rounded-3xl p-10 bg-marshmallow-white w-full">
          <div className="mission-title items-center flex justify-center text-5xl uppercase font-bold">
            Our Mission
          </div>
          <div className="mission-description text-4xl mt-5 items-center flex justify-center max-w-8xl">
            We're on a whimsical quest at Marshmallow to sprinkle a little more
            fun into the world!
            <br />
            Our journey?
            <br />
            To take our zany, colorful Marshmallows from cool digital art in the
            web3 space to huggable, holdable toys in stores worldwide.
            <br />
            We're all about mixing giggles with collectibles and bringing a dash
            of marshmallow magic to both virtual and real playgrounds!
          </div>
        </div>
        <div className="umgm-container flex-col gap-12 flex justify-between mt-12 lg:flex-row transition-all ease-in-out duration-100">
          <div className="um-container bg-marshmallow-white w-auto flex flex-col overflow-hidden p-10 rounded-3xl">
            <div className="um-container-title border-b-2 border-black border-solid uppercase text-4xl font-bold">
              use marshmallow
            </div>
            <div className="um-container-description font-normal text-2xl mt-8">
              MARSHMALLOW BOASTS 1 INTEGRATIONS across the following categories
            </div>
            <table className="um-table text-xl p-1 w-full mt-8 border-collapse border-t-2 border-b-2 border-solid border-black">
              <tbody>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    0
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    DeFi
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    0
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    Developer
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    1
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    Exchange
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    0
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    Gaming
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    0
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    NFT
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    0
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    Payment
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    0
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    Social
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    0
                  </td>
                  <td className="border-t-2 border-b-2 border-solid border-black text-xl p-1 text-right">
                    Wallet
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="um-button w-full bg-marshmallow-pink text-white border-none rounded-lg cursor-pointer px-6 uppercase transition-colors duration-500 whitespace-nowrap mt-8 text-2xl h-12 hover:bg-marshmallow-purple"
              onClick={navigateToIntegrations}
            >
              read more
            </button>
          </div>
          <div className="gm-container bg-marshmallow-white w-auto flex h-fit flex-col overflow-hidden p-10 rounded-3xl">
            <div className="gm-container-title border-b-2 border-black border-solid uppercase text-4xl font-bold">
              get marshmallow
            </div>
            <div className="gm-container-description text-2xl mt-8 uppercase">
              marshmallow IS LISTED on over &nbsp;1&nbsp; different dexs &amp;
              CEXs, BUY MARSHMALLOW TODAY!
            </div>
            <table className="gm-table text-xl p-1 w-full mt-8 border-collapse border-t-2 border-b-2 border-solid border-black">
              <tbody>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://app.uniswap.org/swap"
                      target="_blank"
                    >
                      UniSwap
                    </Link>
                  </td>
                </tr>
                {/* <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://www.coinbase.com/"
                      target="_blank"
                    >
                      COINBASE
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://www.binance.com/"
                      target="_blank"
                    >
                      Binance
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://www.kucoin.com/"
                      target="_blank"
                    >
                      KuCoin
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://www.bybit.com/"
                      target="_blank"
                    >
                      BYBIT
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://jup.ag/"
                      target="_blank"
                    >
                      JUPITER EXCHANGE
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://www.huobi.com/"
                      target="_blank"
                    >
                      HTX (FORMERLY HUOBI)
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://crypto.com/"
                      target="_blank"
                    >
                      Crypto.com
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://www.bitget.com/"
                      target="_blank"
                    >
                      BITGET
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    <Link
                      className="no-text-decoration white uppercase"
                      to="https://www.gate.io/"
                      target="_blank"
                    >
                      GATE.IO
                    </Link>
                  </td>
                </tr>
                <tr className="cursor-pointer">
                  <td className="gm-td border-t-2 border-b-2 border-solid border-black text-xl p-1">
                    AND MANY MORE
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
