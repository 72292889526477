import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="text-[#3f93a3] p-4">
      <div className="container mx-auto text-center">
        <div className="flex flex-wrap justify-center gap-8 mb-4">
          <a
            href="https://twitter.com/Official_MLOW"
            target="_blank"
            rel="noopener noreferrer"
            className="lg:hover:text-black lg:hover:underline"
          >
            Twitter
          </a>
          <a
            href="https://medium.com/@Official_MLOW"
            target="_blank"
            rel="noopener noreferrer"
            className="lg:hover:text-black lg:hover:underline"
          >
            Medium
          </a>
          <a href="#" className="lg:hover:text-black lg:hover:underline">
            Discord (TBD)
          </a>
          <a
            href="https://t.me/Official_MLOW"
            target="_blank"
            rel="noopener noreferrer"
            className="lg:hover:text-black lg:hover:underline"
          >
            Telegram
          </a>
          <a
            href="https://instagram.com/Official_MLOW"
            target="_blank"
            rel="noopener noreferrer"
            className="lg:hover:text-black lg:hover:underline"
          >
            Instagram
          </a>
          <a
            href="https://www.youtube.com/@Official_MLOW"
            target="_blank"
            rel="noopener noreferrer"
            className="lg:hover:text-black lg:hover:underline"
          >
            YouTube
          </a>
          <a href="#" className="lg:hover:text-black lg:hover:underline">
            CoinMarketCap (TBD)
          </a>
          <Link
            to="/marshmallowpaper"
            className="lg:hover:text-black lg:hover:underline"
          >
            Marshmallow Paper
          </Link>
        </div>
        <p>
          &copy; {new Date().getFullYear()} Marshmallow. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
