import React, { useState } from "react";
import PlusIcon from "../imgs/plus.png";

function Integrations() {
  return (
    <main className="site-content mx-auto p-4 pt-40">
      <div className="about-main-container items-center flex flex-col justify-center overflow-hidden bg-marshmallow-white rounded-[40px] p-10 shadow">
        <h2 className="about-history-title text-3xl font-bold mb-4 uppercase text-center">
          Integrations - COMING SOON
        </h2>
      </div>
    </main>
  );
}

export default Integrations;
