import React, { useState } from "react";

function MarshmallowPaper() {
  const [lastEdited] = useState("December 26, 2023");

  return (
    <main className="site-content mx-auto p-4 pt-40">
      <h2 className="marshmallowpaper-title text-3xl font-bold mb-10 uppercase text-center">
        Marshmallow Paper
      </h2>
      <div className="marshmallowpaper-main-container mx-auto items-center flex max-w-[1672px] flex-col justify-center overflow-hidden bg-marshmallow-white rounded-[40px] p-4 shadow">
        <div className="marshmallowpaper-legal-container flex flex-col justify-center overflow-hidden bg-white w-full rounded-[40px] p-10 mb-10 shadow">
          <h2 className="marshmallowpaper-legal-title text-3xl font-bold mb-10 uppercase text-center">
            Legal Disclaimer
          </h2>
          <p className="mb-4">
            Please read this{" "}
            <span className="font-bold">"Legal Disclaimer"</span> thoroughly.
            Nothing in this section constitutes legal, financial, business, or
            tax advice, and it is highly recommended that you seek advice from
            your own legal, financial, tax, or other professional advisor(s)
            before participating in any related activities. Neither Digi Globe
            LLC <span className="font-bold text-red-500">(</span>the{" "}
            <span className="font-bold">"Company"</span>
            <span className="font-bold text-red-500">)</span>, any early
            contributors to the Marshmallow project{" "}
            <span className="font-bold text-red-500">(</span>the{" "}
            <span className="font-bold">"Marshmallow Early Contributors"</span>
            <span className="font-bold text-red-500">)</span>, any distributors
            or vendors of <span className="font-bold">MLOW</span> tokens (or any
            renamed or successor ticker code or name of such tokens){" "}
            <span className="font-bold text-red-500">(</span>the{" "}
            <span className="font-bold">“Distributor"</span>
            <span className="font-bold text-red-500">)</span>, nor any service
            providers will be liable for any direct or indirect damage or loss
            you may incur in relation to accessing the Paper, Deck, or any
            materials related to <span className="font-bold">MLOW</span> ("Token
            Documentation") available on the website at{" "}
            <a
              className="text-blue-500 hover:underline hover:text-blue-600 transition-all"
              href="https://www.marshmallownft.io/"
            >
              https://www.marshmallownft.io/
            </a>{" "}
            <span className="font-bold text-red-500">(</span>the{" "}
            <span className="font-bold">"Website"</span>, including any
            sub-domains<span className="font-bold text-red-500">)</span> or any
            other materials published or communicated by the Company or its
            representatives.
          </p>
          <p className="mb-4">
            <span className="font-bold">Project Purpose:</span> You acknowledge
            that your acquisition of <span className="font-bold">MLOW</span> is
            for the purpose of participating in the Marshmallow ecosystem and
            obtaining services therein. The Company, the Distributor, and their
            respective affiliates will develop and contribute to the underlying
            source code for the Marshmallow ecosystem. The Company is merely
            facilitating the MLOW distribution as an independent third party and
            is not acting as a financial advisor or fiduciary for anyone in the
            distribution of <span className="font-bold">MLOW</span>.
          </p>
          <p className="mb-4">
            <span className="font-bold">Token utility:</span>{" "}
            <span className="font-bold">MLOW</span>, the native fungible
            protocol token of the Marshmallow ecosystem (ticker symbol{" "}
            <span className="font-bold">MLOW</span>), represents attributed
            utility functions specified in the protocol/code of the ecosystem.
            It is designed for interoperable utility within the ecosystem.
          </p>
          <p className="mb-4">
            <span className="font-bold">MLOW</span> serves as a multi-utility
            token, facilitating decentralized exchange between participants in
            the Marshmallow ecosystem without the need for centralized
            intermediaries. The introduction of{" "}
            <span className="font-bold">MLOW</span> aims to provide a convenient
            and secure payment and settlement method for participants
            interacting within the ecosystem.{" "}
            <span className="font-bold">MLOW</span> is not a general medium of
            exchange and is not intended to be used as payment for goods or
            services outside the issuer-provided ecosystem or as a
            representation of any shareholding, participation, right, title, or
            interest in the Company, the Distributor, or any other entity.
            Ownership of <span className="font-bold">MLOW</span> carries no
            rights other than enabling usage and interaction within the
            Marshmallow ecosystem. The value of{" "}
            <span className="font-bold">MLOW</span> in secondary markets is not
            tied to the efforts of the Marshmallow Early Contributors, and there
            are no mechanisms designed to control or influence such secondary
            market prices.
          </p>
          <p className="mb-4">
            <span className="font-bold">MLOW</span> is crucial to the
            Marshmallow ecosystem, providing economic incentives to users for
            their contributions and participation, creating a system where each
            participant is fairly compensated. These incentives are distributed
            based on actual usage, activity, and effort within the ecosystem, as
            well as the frequency and volume of transactions. Inactive users or
            those not actively participating will not receive{" "}
            <span className="font-bold">MLOW</span> incentives.
          </p>
          <p className="mb-4">
            <span className="font-bold">
              Nature of the Token Documentation:
            </span>{" "}
            The Token Documentation is a conceptual paper that articulates some
            of the main design principles and ideas for the creation of a
            digital token to be known as <span className="font-bold">MLOW</span>
            . The Token Documentation and the Website are intended for general
            informational purposes only and do not constitute a prospectus, an
            offer document, an offer of securities, a solicitation for
            investment, any offer to sell any product, item, or asset(whether
            digital or otherwise), or any offer to engage in business with any
            external individual or entity provided in said documentation. The
            information herein may not be exhaustive and does not imply any
            element of , or solicit in any way, a legally-binding or contractual
            relationship.There is no assurance as to the accuracy or
            completeness of such information and no representation, warranty or
            undertaking is or purported to be provided as to the accuracy or
            completeness of such information. Where the Token Documentation or
            the Website includes information that has been obtained from third
            party sources, the Company, the Distributor, their respective
            affiliates and/or the Marshmallow early contributors have not
            independently verified the accuracy or completeness of such
            information. Further, you acknowledge that the project development
            roadmap, network functionality are subject to change and that the
            Token Documentation or the Website may become outdated as a result;
            and neither the Company nor the Distributor is under any obligation
            to update or correct this document in connection therewith.
          </p>
          <p className="mb-4">
            <span className="font-bold">
              Validity of Token Documentation and Website:
            </span>{" "}
            Nothing in the Token Documentation or the Website constitutes any
            offer by the Company, the Distributor, or the Marshmallow early
            contributors to sell any <span className="font-bold">MLOW</span> (as
            defined herein) nor shall it or any part of it nor the fact of its
            presentation form the basis of, or be relied upon in connection
            with, any contract or investment decision. Nothing contained in the
            Token Documentation or the Website is or may be relied upon as a
            promise, representation of undertaking as to the future performance
            of the Marshmallow ecosystem. The agreement between the Distributor
            (or any third party) and you, in relation to any distribution or
            transfer of <span className="font-bold">MLOW</span>, is to be
            governed only by the separate terms and conditions of such
            agreement.
          </p>
          <p className="mb-4">
            The information set out in the Token Documentation and the Website
            is for the community discussion only and is not legally binding. No
            person is bound to enter into any contract of binding legal
            commitment in relation to the acquisition of{" "}
            <span className="font-bold">MLOW</span>, and no digital asset or
            other form of payment is to be accepted on the basis of the Token
            Documentation or the Website. The agreement for distribution of{" "}
            <span className="font-bold">MLOW</span> and/or continued holding of{" "}
            <span className="font-bold">MLOW</span> shall be governed by a
            separate set of Terms and Conditions or Token Distribution Agreement
            (as the case may be) setting out the terms of such distribution
            and/or continued holding of <span className="font-bold">MLOW</span>{" "}
            (the Terms and Conditions), which shall be separately provided to
            you or made available on the Website. The Terms and Conditions must
            be read together with the Token Documentation. In the event of any
            inconsistencies between the Terms and Conditions and the Token
            Documentation or the Website, the Terms and Conditions shall
            prevail.
          </p>
          <p className="mb-4">
            <span className="font-bold">
              Deemed Representations and Warranties:
            </span>{" "}
            By accessing the Token Documentation or the Website (or any part
            thereof), you shall be deemed to represent and warrant to the
            Company, the Distributor, their respective affiliates, and the
            Marshmallow early contributors as follows:
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(a)</span>. In any decision to
            acquire any <span className="font-bold">MLOW</span>, you have not
            relied on and shall not rely on any statement set out in the Token
            Documentation or the Website;
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(b)</span>. You will and shall
            at your own expense ensure compliance with all laws, regulatory
            requirements and restrictions applicable to you (as the case may
            be);
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(c)</span>. You acknowledge,
            understand and agree that <span className="font-bold">MLOW</span>{" "}
            may have no value, there is no guarantee or representation of value
            or liquidity for MLOW, and MLOW is not an investment product nor is
            it intended for any speculative investment whatsoever;
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(d)</span>. None of the Company,
            the Distributor, their respective affiliates, and/or the Marshmallow
            early contributors shall be responsible for or liable for the value
            of <span className="font-bold">MLOW</span>, the transferability
            and/or liquidity of <span className="font-bold">MLOW</span> and/or
            the availability of any market for{" "}
            <span className="font-bold">MLOW</span> through third parties
            otherwise; and
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(e)</span>. You acknowledge,
            understand and agree that you are not eligible to participate in the
            distribution of <span className="font-bold">MLOW</span> is you are a
            citizen, national, resident (tax or otherwise), domiciliary and/or
            green card holder of a geographic area or country (i) where it is
            likely that the distribution of{" "}
            <span className="font-bold">MLOW</span> would be construed as the
            sale of a security (however named), financial service or investment
            product and/or (ii) where participation in token distributions is
            prohibited by applicable law, decree, regulation, treaty, or
            administrative act (including without limitation the United States
            of America, Canada, and the People’s Republic of China); and to this
            effect you agree to provide all such identity verification document
            when requested in order for the relevant checks to be carried out.
          </p>
          <p className="mb-8">
            The Company, the Distributor and the Marshmallow early contributors
            do not and do not purport to make, and hereby disclaims, all
            representations, warranties or undertaking to any entity or person
            (including without limitation warranties as to the accuracy,
            completeness, timeliness, or reliability of the contents of the
            Token Documentation or the Website, or any other materials published
            by the Company or the Distributor). To the maximum extent permitted
            by the law, the Company, the Distributor, their respective
            affiliates and service providers shall not be liable for any
            indirect, special, incidental, consequential or other losses of any
            kind, in tort, contract or otherwise (including, without limitation,
            any liability arising from default or negligence on the part of any
            of them, or any loss of revenue, income or profits, and loss of use
            of data) arising from the use of the Token Documentation or the
            Website, or any other materials published, or its contents
            (including without limitation any errors or omissions) or otherwise
            arising in connection with the same. Prospective acquirers of{" "}
            <span className="font-bold">MLOW</span> should carefully consider
            and evaluate all risks and uncertainties (including financial and
            legal risks and uncertainties) associated with the distribution of{" "}
            <span className="font-bold">MLOW</span>, the Company, the
            Distributor and the Marshmallow early contributors.
          </p>
          <p className="mb-4">
            <span className="font-bold">MLOW Token:</span>{" "}
            <span className="font-bold">MLOW</span> are designed to be utilized,
            and that is the goal of <span className="font-bold">MLOW</span>{" "}
            distribution. In particular, it is highlighted that{" "}
            <span className="font-bold">MLOW</span>
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(a)</span>. Does not have any
            tangible or physical manifestation, and does not have any intrinsic
            value (nor does any person make any representation or give any
            commitment as to its value)
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(b)</span>. Is non-refundable
            and cannot be exchanged for cash (or its equivalent value in any
            other digital asset) or any payment obligation by the Company, the
            Distributor or any of their respective affiliates;
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(c)</span>. Does not represent
            or confer on the token holder any right of any form with respect to
            the Company, the Distributor (or any of their respective
            affiliates), or their revenues or assets, including without
            limitation any right to receive future dividend, revenue, shares,
            ownership right or stake, share or security, any voting,
            distribution, redemption, liquidation, proprietary(including all
            forms of intellectual property or license rights), right to receive
            accounts, financial statements or other financial data, the right to
            requisition or participate in shareholder meetings, the right to
            nominate a director, or other financial or legal rights or
            equivalent rights, or intellectual property rights or any other form
            of participation in or relating to the Marshmallow ecosystem, the
            Company the Distributor and/or their service providers;
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(d)</span>. Is not intended to
            represent any rights under a contract for differences or under any
            other contract the purpose or pretended purpose of which is to
            secure a profit or to avoid a loss;
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(e)</span>. Is not intended to
            be a representation of money (including electronic money), security,
            commodity, bond, debt instrument, unit in a collective investment
            scheme or any other kind of financial instrument or investment;
          </p>
          <p className="mb-4 ml-4">
            <span className="font-bold text-sm">(f)</span>. Is not a loan to the
            Company, the Distributor or any of their respective affiliates, is
            not intended to represent a debt owed by the Company, the
            Distributor or any of their respective affiliates, and there is no
            expectation of profit; and
          </p>
          <p className="mb-8 ml-4">
            <span className="font-bold text-sm">(g)</span>. Does not provide the
            token holder with any ownership or other interest in the Company,
            the Distributor or any of their respective affiliates.
          </p>
          <p className="mb-4">
            Notwithstanding <span className="font-bold">MLOW</span>{" "}
            distribution, users have no economic or legal right over or
            beneficial interest in the assets of the Company, the Distributor,
            or any of their affiliates after the token distribution.
          </p>
          <p className="mb-4">
            To the extent a secondary market or exchange for trading{" "}
            <span className="font-bold">MLOW</span> does develop, it would be
            run and operated wholly independently of the Company, the
            Distributor, the distribution of{" "}
            <span className="font-bold">MLOW</span> and the Marshmallow
            ecosystem. Neither the Company nor the Distributor will create such
            secondary markets nor will either entity act as an exchange for{" "}
            <span className="font-bold">MLOW</span>.
          </p>
          <p className="mb-4">
            <span className="font-bold">Informational purposes only:</span> The
            information set out herein is only conceptual, and describes the
            future development goals for the Marshmallow ecosystem to be
            developed. In particular, the project roadmap in the Token
            Documentation is being shared in order to outline some of the plans
            of the Marshmallow early contributors, and is provided solely for
            INFORMATIONAL PURPOSES and does not constitutes any binding
            commitment, Please do not rely on this information in deciding
            whether to participate in the token distribution because ultimately,
            the development, release, and timing of any products, features or
            functionality remains at the sole discretion of the Company, the
            Distributor or their respective affiliates, and is subject to
            change. Further, the Token Documentation or the Website may be
            amended or replaced from time to time. There are no obligations to
            update the Token Documentation or the Website, or to provide
            recipients with access to any information beyond what is provided
            herein.
          </p>
          <p className="mb-4">
            <span className="font-bold">Regulatory approval:</span> No
            regulatory authority has examined or approved, whether formally or
            informally, any of the information set out in the Token
            Documentation or the Website. No such action or assurance has been
            or will be taken under the laws, regulatory requirements or rules of
            any jurisdiction. The publication, distribution or dissemination of
            the Token Documentation or the Website does not imply that the
            applicable laws, regulatory requirements or rules have been complied
            with.
          </p>
          <p className="mb-4">
            <span className="font-bold">
              Cautionary Note on forward-looking statements:
            </span>{" "}
            All statements contained herein, statements made in press releases
            or in any place accessible by the public and oral statements that
            may be made by the Company, the Distributor and/or the Marshmallow
            early contributor, may constitute forward-looking statements
            (including statements regarding the intent, belief or current
            expectations with respect to market conditions, business strategy
            and plans, financial condition, specific provisions and risk
            management practices). You are cautioned not to place undue reliance
            on these forward-looking statements given that these statements
            involve known and unknown risks, uncertainties and other factors
            that may cause the actual future results to be materially different
            from that described by such forward-looking statements, and no
            independent third party has reviewed the reasonableness of any such
            statements or assumptions. These forward-looking statements are
            applicable only as of the date indicated in the Token Documentation,
            and the Company, the Distributor as well as the Marshmallow early
            contributors expressly disclaim any responsibility (whether express
            or implied) to release any revisions to these forward-looking
            statements to reflect events after such date.
          </p>
          <p className="mb-4">
            <span className="font-bold">
              References to companies and platforms:
            </span>{" "}
            The use of any company and/or platform names or trademarks herein
            (save for those which relate to the Company, the Distributor or
            their respective affiliates) does not imply any affiliation with, or
            endorsement by, any third party. References in the Token
            Documentation or the Website to specific companies and platforms are
            for illustrative purposes only.
          </p>
          <p className="mb-4">
            <span className="font-bold">English language:</span> The Token
            Documentation and the Website may be translated into a language
            other than English for reference purpose only and in the event of
            conflict or ambiguity between the English language version and
            translated versions of the Token Documentation or the Website, the
            English language versions shall prevail. You acknowledge that you
            have read and understood the English language version of the Token
            Documentation and the Website.
          </p>
          <p className="mb-4">
            <span className="font-bold">No Distribution:</span> No part of the
            Token Documentation of the Website is to be copied, reproduced,
            distributed, or disseminated in any way without the prior written
            consent of the Company or the Distributor. By attending any
            presentation on the Token Documentation or by accepting any hard or
            soft copy of the Token Documentation, you agree to be bound by the
            foregoing limitations.
          </p>
        </div>
        <div className="marshmallowpaper-corethesis-container flex flex-col justify-center overflow-hidden w-full bg-white rounded-[40px] p-10 mb-10 shadow">
          <h2 className="marshmallowpaper-corethesis-title text-3xl underline font-bold mb-10 uppercase text-center">
            Core Thesis
          </h2>
          <p className="mb-4">
            Crypto has often fallen prey to exploitative tokens that undermine
            the broader community. Marshmallow is designed to reverse this trend
            and restore power to the true builders of this network — the
            community members.
          </p>
          <p className="mb-4">
            Marshmallow aims to be the quintessential community token of the
            blockchain, widely adopted across the diverse and thriving DApps of
            this ecosystem. Rather than being concentrated in corporate hands,
            Marshmallow will be owned by the individuals who constitute the
            vital social fabric of this powerful network.
          </p>
          <p className="mb-4">
            Specifically, 35% of the tokens will be made available through a
            fair launch on Uniswap, embodying our commitment to equal access and
            dedicating a portion to development to ensure our roadmap is fully
            realized.
          </p>
          <p className="mb-4">
            Our allocation strategy is designed to align with and reinforce
            incentives, ensuring the sustained prosperity of the Marshmallow
            ecosystem. This includes community allocations, along with reserves
            for marketing initiatives, development efforts, and grants that
            promote the widespread uptake of Marshmallow.
          </p>
          <p className="mb-4">
            Driving the Marshmallow mission forward are its early contributors,
            each deeply invested in the long-term prosperity of the blockchain's
            definitive community coin. Demonstrating their dedication to the
            Marshmallow ecosystem, each contributor will adhere to a 3-year
            linear vesting schedule, cementing their commitment to Marshmallow's
            enduring success.
          </p>
        </div>
        <div className="marshmallowpaper-mlow-container flex flex-col justify-center overflow-hidden w-full bg-white rounded-[40px] p-10 mb-10 shadow">
          <h2 className="marshmallowpaper-mlow-title text-3xl underline font-bold mb-10 uppercase text-center">
            MLOW
          </h2>
          <p className="mb-4">
            2022 was a challenging year for the crypto community. Many coins,
            through no fault of their own, became targets of predatory
            individuals who exploited the retail market for personal gain. Yet,
            despite these hardships, the crypto community has remained
            resilient, and this vibrant ecosystem continues to expand with each
            passing day.
          </p>
          <p className="mb-4">
            In 2023, the crypto community reclaimed control of the network, with
            numerous coins at the forefront of this resurgence. While MLOW was
            not part of this initial comeback, we are poised to be leaders in
            the movement going forward. MLOW's primary goal is to act as the
            community token, facilitating transactions across various chains and
            integrating with numerous distinguished DApps. In contrast to tokens
            that are sold at exorbitant valuations, MLOW will be distributed
            through a fair launch, ensuring that those who contribute most to
            the community have an equitable chance to acquire their rightful
            share.
          </p>
          <p className="mb-4">
            Beyond rewarding the current crypto community, MLOW aims to attract
            users from the broader blockchain, NFT, and toy communities,
            potentially serving as a gateway for newcomers to the world of Web3.
            As with previous community-focused tokens, MLOW intends to offer an
            accessible entry point for these new users. Through its extensive
            planned integrations, MLOW seeks to guide newcomers through the
            intricacies of self-custody, decentralized finance, NFTs, and
            beyond.
          </p>
          <p className="mb-4">
            MLOW is set to benefit from the experiences of earlier community
            coins, aiming to distribute tokens to those members of the community
            who are most likely to realize MLOW's intended utility. Accordingly,
            MLOW will distribute a total of 56.65% of its entire supply (56
            Trillion out of the total 100 Trillion) into four distinct groups.
          </p>
          <p className="mb-4">
            Driving the Marshmallow mission forward are its early contributors,
            each deeply invested in the long-term prosperity of the blockchain's
            definitive community coin. Demonstrating their dedication to the
            Marshmallow ecosystem, each contributor will adhere to a 3-year
            linear vesting schedule, cementing their commitment to Marshmallow's
            enduring success.
          </p>
        </div>
        <div className="marshmallowpaper-dist-container flex flex-col justify-center overflow-hidden w-full bg-white rounded-[40px] p-10 mb-10 shadow">
          <h2 className="marshmallowpaper-dist-title text-3xl underline font-bold mb-10 uppercase text-center">
            Distribution
          </h2>
          <p className="mb-4">
            <span className="font-bold">NFT Phase & Toys - 25.77%</span>
            <li className="ml-10">
              Allocated for the evolution of $MLOW into the NFT space and later
              into the exciting world of physical toys, this fund supports the
              creative journey from digital art to tangible collectibles. It's a
              commitment to bringing the Marshmallow spirit into both the
              virtual and physical worlds.
            </li>
          </p>
          <p className="mb-4">
            <span className="font-bold">Liquidity - 20.22%</span>
            <li className="ml-10">
              Ensuring smooth and stable transactions for all users, this
              portion is allocated to create liquidity pools. It's a
              foundational step towards a reliable and accessible trading
              experience, inviting everyone to join the Marshmallow journey with
              confidence.
            </li>
          </p>
          <p className="mb-4">
            <span className="font-bold">Development - 5.33%</span>
            <li className="ml-10">
              Fueling continuous innovation and improvement, the development
              fund is key to the technological advancement of the Marshmallow
              Token. It's where ideas come to life, ensuring $MLOW stays at the
              forefront of the digital token revolution.
            </li>
          </p>
          <p className="mb-10">
            <span className="font-bold">Marketing - 5.33%</span>
            <li className="ml-10">
              Allocated for spreading the joy and vision of Marshmallow far and
              wide, the marketing fund is where engaging stories are told and
              new members are welcomed. It's about sharing the journey and
              inviting the world to be part of the Marshmallow community.
            </li>
          </p>
          <p className="mb-4">
            <span className="font-bold">Community - 33.24%</span>
            <li className="ml-10">
              The remaining 33.24% is dedicated to nurturing a vibrant and
              active community, this allocation fosters the growth and
              sustainability of the Marshmallow ecosystem. It's here where
              engagement, innovation, and collaborative development are
              paramount, creating a welcoming space for all participants.
            </li>
          </p>
        </div>
        <div className="marshmallowpaper-cta-container flex flex-col justify-center overflow-hidden w-full bg-white rounded-[40px] p-10 mb-10 shadow">
          <h2 className="marshmallowpaper-cta-title text-3xl font-bold uppercase text-center">
            ALL MLOW RESERVES WOULD BE SET ASIDE FOR A VARIETY OF INITIATIVES TO
            DEVELOP A SELF-SUSTAINABLE SYSTEM. THE MARSHMALLOW EARLY
            CONTRIBUTORS DO NOT CREATE OR SUPPORT THE MARKET FOR MLOW, NOR DO
            THEY ACTIVELY TAKE ANY ACTION TO SUPPORT A MARKET PRICE OF THE
            DIGITAL ASSET (E.G. BY LIMITING SUPPLY OR ENSURING SCARCITY).
          </h2>
        </div>
        <div className="marshmallowpaper-risks-container flex flex-col justify-center overflow-hidden w-full bg-white rounded-[40px] p-10 mb-10 shadow">
          <h2 className="marshmallowpaper-risks-title text-2xl mb-8 font-bold uppercase text-left">
            Risks
          </h2>
          <p className="mb-4">
            The Marshmallow ecosystem is currently in the initial development
            stages and there are a variety of unforeseeable risks. You
            acknowledge and agree that there are numerous risks associated with
            acquiring <span className="font-bold">MLOW</span>, holding{" "}
            <span className="font-bold">MLOW</span>, and using{" "}
            <span className="font-bold">MLOW</span> for participation in the
            Marshmallow ecosystem. In the worst scenario, this could lead to the
            loss of all or part of <span className="font-bold">MLOW</span> held.
            <br />
            <span className="font-bold">
              IF YOU DECIDE TO ACQUIRE MLOW OR PARTICIPATE IN THE MLOW
              ECOSYSTEM, YOU EXPRESSLY ACKNOWLEDGE, ACCEPT AND ASSUME THE
              FOLLOWING RISKS:
            </span>
          </p>
          <p className="mb-4">
            <li className="ml-10">
              <span className="font-bold">
                Uncertain Regulations and Enforcement Actions:
              </span>{" "}
              The regulatory status of the Marshmallow ecosystem,{" "}
              <span className="font-bold">MLOW</span> and distributed ledger
              technology is unclear or unsettled in many jurisdictions. The
              regulation of digital assets has become a primary target of
              regulation in all major countries in the world. It is impossible
              to predict how, when or whether regulatory agencies may apply
              existing regulations or create new regulations with respect to
              such technology and its applications, including{" "}
              <span className="font-bold">MLOW</span> and/or the Marshmallow
              ecosystem. Regulatory actions could negatively impact{" "}
              <span className="font-bold">MLOW</span> and/or the Marshmallow
              ecosystem in various ways. The Company, the Distributor (or their
              respective affiliates) may cease operations in a jurisdiction in
              the event that regulatory actions, or changes to law or
              regulation, make it illegal to operate in such jurisdiction, or
              commercially undesirable to obtain the necessary regulatory
              approval(s) to operate in such jurisdiction. After consulting with
              a wide range of legal advisors to mitigate the legal risks as much
              as possible, the Company and Distributor have worked with the
              specialist blockchain department at ____________ and obtained a
              legal opinion on the token distribution, and will be conducting
              business in accordance with the prevailing market practice.to be
              bound by the forgoing limitations.
            </li>
          </p>
          <p className="mb-4">
            <li className="ml-10">
              <span className="font-bold">
                Inadequate disclosure of information:
              </span>{" "}
              As at the date hereof, the Marshmallow ecosystem is still under
              development and its design concepts, consensus mechanisms,
              algorithms, codes, and other technical details and parameters may
              be constantly and frequently updated and changed. Although this
              material contains the most current information relating to the
              Marshmallow ecosystem, it is not absolutely complete and may still
              be adjusted and updated by the Marshmallow early contributors from
              time to time. The Marshmallow early contributors have neither the
              ability nor obligation to keep holders of{" "}
              <span className="font-bold">MLOW</span> informed of every detail
              (including development progress and expected milestones) regarding
              the project to develop the Marshmallow ecosystem, hence
              insufficient information disclosure is inevitable and reasonable.
            </li>
          </p>
          <p className="mb-4">
            <li className="ml-10">
              <span className="font-bold">Loss of Talent:</span> The development
              of the Marshmallow ecosystem greatly depends on the continued
              co-operation of the existing early contributors and expert
              consultants, who are highly knowledgeable and experienced in their
              respective sectors. The loss of any member may adversely affect
              the Marshmallow ecosystem or its future development. Further,
              stability and cohesion within the early contributors is critical
              to the overall development of the Marshmallow ecosystem. There is
              the possibility that conflict within the early contributors and/or
              departure of core personnel may occur, resulting in negative
              influence on the project in the future.
            </li>
          </p>
          <p className="mb-4">
            <li className="ml-10">
              <span className="font-bold">Failure to develop:</span> There is
              the risk that the development of the Marshmallow ecosystem will
              not be executed or implemented as planned, for a variety of
              reason, including without limitation the event of a decline in the
              prices of any digital asset, virtual currency or{" "}
              <span className="font-bold">MLOW</span>, unforeseen technical
              difficulties, and shortage of development funds for activities.
            </li>
          </p>
          <p className="mb-4">
            <li className="ml-10">
              <span className="font-bold">Security weaknesses:</span> Hackers or
              other malicious groups or organizations may attempt to interfere
              with <span className="font-bold">MLOW</span> and/or the
              Marshmallow ecosystem in a variety of ways, including, but not
              limited to, malware attacks, denial of service attacks,
              consensus-based attacks, Sybil attacks, smurfing and spoofing.
              Furthermore, there is a risk that a third party or a member of the
              Company, the Distributor or their respective affiliates may
              intentionally or unintentionally introduce weaknesses into the
              core infrastructure of <span className="font-bold">MLOW</span>{" "}
              and/or the Marshmallow ecosystem, which could negatively affect{" "}
              <span className="font-bold">MLOW</span> and/or the Marshmallow
              ecosystem. Further, the future of cryptography and security
              innovations are highly unpredictable and advances in cryptography,
              or technical advances (including without limitation development of
              quantum computing), could present unknown risks to{" "}
              <span className="font-bold">MLOW</span> and/or the Marshmallow
              ecosystem by rendering ineffective the cryptographic consensus
              mechanism that underpins the blockchain protocol.
            </li>
          </p>
          <p className="mb-4">
            <li className="ml-10">
              <span className="font-bold">Risk of Dissolution:</span> Start-up
              companies such as the Company, the Distributor or their affiliates
              involve a high degree of risk. Financial and operating risks
              confronting start-up companies are significant, and the
              aforementioned entities are not immune to these. Start-up
              companies often experience unexpected problems in the areas of
              product development, marketing, financing, and general management,
              among others, which frequently cannot be solved. It is possible
              that, due to any number of reasons, including, but not limited to,
              an unfavorable fluctuation in the value of cryptographic and fiat
              currencies, decrease in the utility of{" "}
              <span className="font-bold">MLOW</span> due to the negative
              adoption of the Marshmallow ecosystem, the failure of commercial
              relationships, or intellectual property ownership related
              challenges, the Marshmallow ecosystem may no longer be viable to
              operate and the Company, the Distributor or their affiliates may
              be dissolved.
            </li>
          </p>
          <p className="mb-4">
            <li className="ml-10">
              <span className="font-bold">Other risks:</span> In addition, the
              potential risks briefly mentioned above are not exhaustive and
              there are other risks (as more particularly set out in the Terms
              and Conditions) associated with your participation in the
              Marshmallow ecosystem, as well as acquisition of, holding and use
              of <span className="font-bold">MLOW</span>, including those that
              the Company or the Distributor cannot anticipate. Such risks may
              further materialize as unanticipated variations or combinations of
              the aforementioned risks. You should conduct full due diligence on
              the Company, the Distributor, their respective affiliates, and the
              Marshmallow early contributors, as well as understand the overall
              framework, mission, and vision.
            </li>
          </p>
        </div>
      </div>
      {/* Last Edited stamp at the bottom of your component */}
      <p className="text-right mr-10 mt-5 text-sm text-gray-500">
        Last Edited: {lastEdited}
      </p>
    </main>
  );
}

export default MarshmallowPaper;
