import React, { useState, useEffect } from "react";

const roadmapData = [
  {
    phase: "Phase 1",
    title: "Launch & Initial Listings",
    timeline: "Q1 2024",
    goals: [
      { text: "Official launch of $MLOW token.", completed: true },
      { text: "Initial CEX & DEX listings.", completed: true },
      {
        text: "Community engagement and brand advocacy initiatives.",
        completed: false,
      },
      {
        text: "Develop strategic partnerships with fintech influencers.",
        completed: false,
      },
      {
        text: "Host virtual launch event with live Q&A sessions.",
        completed: false,
      },
    ],
    status: "In Progress",
  },
  {
    phase: "Phase 2",
    title: "Defi Ecosystem Development",
    timeline: "Q2 2024",
    goals: [
      { text: "Introduction of Marshmallow Swap.", completed: false },
      {
        text: "Launch of staking mechanisms and DeFi integrations.",
        completed: false,
      },
      { text: "Enable direct off-chain $MLOW purchases.", completed: false },
      {
        text: "Implement yield farming incentives for early adopters.",
        completed: false,
      },
      {
        text: "Conduct an audit of smart contracts for security assurance.",
        completed: false,
      },
    ],
    status: "Upcoming",
  },
  {
    phase: "Phase 3",
    title: "NFT Marketplace Rollout",
    timeline: "Q3 2024",
    goals: [
      {
        text: "Launch of NFT minting and purchasing capabilities.",
        completed: false,
      },
      {
        text: "Establishment of Marshmallow Rent for NFT lending.",
        completed: false,
      },
      {
        text: "Community-driven NFT projects and partnerships.",
        completed: false,
      },
      {
        text: "Introduce a creator's fund to support digital artists.",
        completed: false,
      },
      {
        text: "Organize online NFT art exhibitions and auctions.",
        completed: false,
      },
    ],
    status: "Upcoming",
  },
  {
    phase: "Phase 4",
    title: "Gaming & Social Features",
    timeline: "Q4 2024",
    goals: [
      { text: "Integration with blockchain-based games.", completed: false },
      {
        text: "Community competitions and social media expansions.",
        completed: false,
      },
      {
        text: "Enhanced user engagement with interactive features.",
        completed: false,
      },
      {
        text: "Launch a referral program for expanded user base.",
        completed: false,
      },
      {
        text: "Partner with gaming studios for exclusive content.",
        completed: false,
      },
    ],
    status: "Upcoming",
  },
  {
    phase: "Phase 5",
    title: "Toy Production & Retail Strategy",
    timeline: "Q1 2025",
    goals: [
      { text: "Start of Marshmallow-themed toy production.", completed: false },
      {
        text: "Integration of $MLOW tokens in loyalty programs.",
        completed: false,
      },
      { text: "Partnerships with major retail outlets.", completed: false },
      {
        text: "Set up an online store for direct merchandise sales.",
        completed: false,
      },
      {
        text: "Collaborate with toy influencers for marketing.",
        completed: false,
      },
    ],
    status: "Upcoming",
  },
  {
    phase: "Phase 6",
    title: "Metaverse Presence & Wallet Support",
    timeline: "Q2 2025",
    goals: [
      {
        text: "Development of the Marshmallow virtual world.",
        completed: false,
      },
      {
        text: "Integration with existing Metaverse platforms.",
        completed: false,
      },
      {
        text: "Enhanced wallet support for $MLOW storage and transactions.",
        completed: false,
      },
      {
        text: "Launch interactive, virtual reality experiences.",
        completed: false,
      },
      {
        text: "Organize virtual meetups and networking events.",
        completed: false,
      },
    ],
    status: "Speculative",
  },
];

function Roadmap() {
  const [showTimeline, setShowTimeline] = useState(window.innerWidth > 1536);

  useEffect(() => {
    const handleResize = () => {
      setShowTimeline(window.innerWidth > 1536);
    };
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const CheckMarkSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block mr-2 fill-current text-green-500"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path d="M20.285 6.289a1 1 0 0 0-1.41-.094l-10.36 9.276-4.244-4.244a1 1 0 0 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-.094-1.41z" />
    </svg>
  );
  return (
    <main className="site-content mx-auto p-4 pt-40">
      <h1 className="text-4xl uppercase font-bold text-center mb-8">
        Marshmallow Map
      </h1>
      <div className="pt-10 grid grid-cols-1 md:gap-8 md:grid-cols-2 lg:grid-cols-3">
        {roadmapData.map((phase, index) => (
          <div
            key={index}
            className="mb-8 p-6 rounded-[40px] shadow bg-marshmallow-white"
          >
            <h3 className="text-2xl font-bold mb-2">{phase.phase}</h3>
            <h2 className="text-2xl font-bold mb-4 text-right">
              {phase.title}
            </h2>
            <p className="text-md font-semibold text-gray-600 mb-2">
              Estimated Timeline: {phase.timeline}
            </p>
            <p
              className={`font-semibold mb-4 ${
                phase.status === "Completed"
                  ? "text-green-500"
                  : phase.status === "In Progress"
                  ? "text-yellow-500"
                  : phase.status === "Upcoming"
                  ? "text-blue-500"
                  : "text-purple-500"
              }`}
            >
              Status: {phase.status}
            </p>
            <ul className="list-disc pl-5">
              {phase.goals.map((goal, goalIndex) => (
                <li key={goalIndex} className="text-lg mb-2 flex items-center">
                  <span
                    className={`inline-block ml-1.5 mr-3.5 ${
                      goal.completed ? "hidden" : "visible"
                    }`}
                  >
                    •
                  </span>
                  {goal.completed && <CheckMarkSVG />}
                  {goal.text}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {showTimeline && (
        <div className="roadmap-timeline mt-12 p-4 mb-12 bg-white rounded-[40px] shadow relative">
          <h2 className="text-2xl pt-10 uppercase font-bold text-center mb-6">
            Projected Timeline
          </h2>
          <div className="timeline-container pt-10 w-full flex justify-between">
            {" "}
            {/* Container for dots and lines */}
            {roadmapData.map((phase, index) => (
              <div
                key={index}
                className="timeline-point text-center relative flex-1"
              >
                <div className="point-dot h-4 w-4 bg-blue-500 rounded-full mx-auto mb-1 z-10 relative"></div>
                {index < roadmapData.length - 1 && (
                  <div
                    className="timeline-line absolute top-2 left-0 right-0 h-0.5 bg-blue-300"
                    style={{ transform: "translateX(50%)" }}
                  ></div>
                )}
              </div>
            ))}
          </div>
          <div className="timeline-text pt-2 pb-10 flex justify-between">
            {" "}
            {/* Container for text */}
            {roadmapData.map((phase, index) => (
              <div key={index} className="text-center relative flex-1">
                <p className="text-sm text-marshmallow-blue mt-2">
                  {phase.timeline}
                </p>
                <br />
                <p className="text-lg font-bold">{phase.phase}</p>
                <br />
                <p className="text-md uppercase font-semibold">{phase.title}</p>
                <div className="goals-list m-4 text-left">
                  {phase.goals.map((goal, goalIndex) => (
                    <div key={goalIndex} className="goal-item mb-2">
                      <span
                        className={`inline-block ml-1.5 mr-3.5 ${
                          goal.completed ? "hidden" : "visible"
                        }`}
                      >
                        •
                      </span>
                      {goal.completed && <CheckMarkSVG />}
                      <span
                        className={
                          goal.completed ? "text-green-500" : "text-gray-700"
                        }
                      >
                        {goal.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </main>
  );
}

export default Roadmap;
