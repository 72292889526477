import React, { useState } from "react";
import PlusIcon from "../imgs/plus.png";
import MarshmallowBanner from "../imgs/MarshmallowBanner896x512.png";
import MarshmallowImage from "../imgs/MarshmallowCut.png";

const distributionData = [
  {
    title: "Community - 33.24%",
    description:
      "Dedicated to nurturing a vibrant and active community, this allocation fosters the growth and sustainability of the Marshmallow ecosystem. It's here where engagement, innovation, and collaborative development are paramount, creating a welcoming space for all participants.",
  },
  {
    title: "NFT Phase & Toys - 25.77%",
    description:
      "Allocated for the evolution of $MLOW into the NFT space and later into the exciting world of physical toys, this fund supports the creative journey from digital art to tangible collectibles. It's a commitment to bringing the Marshmallow spirit into both the virtual and physical worlds.",
  },
  {
    title: "Liquidity - 20.22%",
    description:
      "Ensuring smooth and stable transactions for all users, this portion is allocated to create liquidity pools. It's a foundational step towards a reliable and accessible trading experience, inviting everyone to join the Marshmallow journey with confidence.",
  },
  {
    title: "Development - 5.33%",
    description:
      "Fueling continuous innovation and improvement, the development fund is key to the technological advancement of the Marshmallow Token. It's where ideas come to life, ensuring $MLOW stays at the forefront of the digital token revolution.",
  },
  {
    title: "Marketing - 5.33%",
    description:
      "Allocated for spreading the joy and vision of Marshmallow far and wide, the marketing fund is where engaging stories are told and new members are welcomed. It's about sharing the journey and inviting the world to be part of the Marshmallow community.",
  },
];

const textCreativeColor = "text-[#b04dbd]";
const textCurrentColor = "";

function DistributionDetails() {
  const [openSections, setOpenSections] = useState({});
  // Initialize all sections as not active
  const initialActiveStates = distributionData.reduce((states, _, index) => {
    states[index] = false;
    return states;
  }, {});
  const [activeSections, setActiveSections] = useState(initialActiveStates);

  const toggleSection = (index) => {
    setActiveSections((prevActiveSections) => ({
      ...prevActiveSections,
      [index]: !prevActiveSections[index],
    }));
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [index]: !prevOpenSections[index],
    }));
  };

  return (
    <div className="container mx-auto p-4 mt-12">
      {distributionData.map((item, index) => (
        <div
          key={index}
          className="bg-marshmallow-white rounded-lg p-4 mb-4 shadow"
        >
          <div className="flex justify-between items-center">
            <h3
              className={`text-xl font-semibold transition-colors duration-500 ${
                activeSections[index] ? textCreativeColor : textCurrentColor
              }`}
            >
              {item.title}
            </h3>
            <div
              className={`h-8 w-8 bg-marshmallow-blue bg-no-repeat [background-position:50%] [background-size:60%_60%] rounded-[50%] transform transition-transform duration-500 cursor-pointer ${
                openSections[index] ? "rotate-45" : "rotate-0"
              }`}
              onClick={() => toggleSection(index)}
              style={{ backgroundImage: `url(${PlusIcon})` }}
            />
          </div>
          <div
            className={`transition-all duration-500 ${
              openSections[index] ? "opacity-100" : "opacity-0"
            } ${
              openSections[index] ? "max-h-screen" : "max-h-0"
            } overflow-hidden`}
          >
            <p className="mt-2 text-lg">{item.description}</p>
            {/* You can add more detailed sub-points here if needed */}
          </div>
        </div>
      ))}
    </div>
  );
}

function About() {
  const [isUtilityRotated, setIsUtilityRotated] = useState(false);
  const [isUtilityCollapsed, setIsUtilityCollapsed] = useState(true);

  const [isDistributionRotated, setIsDistributionRotated] = useState(false);
  const [isDistributionCollapsed, setIsDistributionCollapsed] = useState(true);

  const [isAboutRotated, setIsAboutRotated] = useState(false);
  const [isAboutCollapsed, setIsAboutCollapsed] = useState(true);

  const collapsed = "collapsed";

  const expanded =
    "expanded [max-height:5600px] [padding-bottom:20px_!important]";

  const toggleUtilityRotation = () => {
    setIsUtilityRotated(!isUtilityRotated);
    setIsUtilityCollapsed(!isUtilityCollapsed);
  };

  const toggleDistributionRotation = () => {
    setIsDistributionRotated(!isDistributionRotated);
    setIsDistributionCollapsed(!isDistributionCollapsed);
  };
  const toggleAboutRotation = () => {
    setIsAboutRotated(!isAboutRotated);
    setIsAboutCollapsed(!isAboutCollapsed);
  };

  return (
    <main className="site-content mx-auto p-4 pt-40">
      <div className="about-main-container items-center flex flex-col justify-center overflow-hidden bg-marshmallow-white rounded-[40px] p-10 shadow">
        <h2 className="about-history-title text-3xl font-bold mb-4 uppercase text-center">
          marshmallow - a brief history
        </h2>
        <div
          className="about-banner-image [background-size:cover] [background-position:center] bg-no-repeat w-[448px] h-[256px] rounded-lg transition-all duration-500 ease-in-out lg:w-[896px] lg:h-[512px]"
          style={{
            backgroundImage: `url(${MarshmallowBanner})`,
          }}
        />
        <div className="about-title-container w-full pt-10 justify-end border-solid border-black border-b-[1px] flex flex-row pb-5">
          <div
            className={`about-image h-12 w-12 bg-marshmallow-blue bg-no-repeat [background-position:50%] [background-size:60%_60%] rounded-[50%] transform ${
              isAboutRotated ? "rotate-45" : "rotate-0"
            } transition-all duration-500 ease-in-out cursor-pointer`}
            style={{
              backgroundImage: `url(${PlusIcon})`,
            }}
            onClick={toggleAboutRotation}
          />
        </div>
        <p className="font-normal text-2xl mb-4 pt-4">
          Once upon a time, in a whimsical land known as Candy Village, a unique
          and sweet little creature was born on Christmas Day in 2023. Her name
          was Marshmallow, and she was the fluffiest, most delightful
          marshmallow anyone had ever seen. Candy Village was a magical place
          where every nook was filled with the scent of sweetness and the air
          sparkled with sugar dust.
        </p>
        <br />
        <div
          className={`${isAboutCollapsed ? collapsed : expanded} 
            font-normal text-2xl [transition:all_2s_ease] border-b border-solid border-black overflow-hidden max-h-[0] pb-0`}
        >
          <p className="font-normal text-2xl">
            Marshmallow lived in a cozy gingerbread house with a frosting roof
            and peppermint windows. She was never alone, for she had a colorful
            circle of friends. Lolly, a cheerful lollipop with swirls of rainbow
            colors; Candy Cane, who wore stripes with pride; Cotton, a fluffy
            cotton candy as soft as a cloud; Gummy, a little gumdrop always
            bouncing with joy; and Cookie, a wise gingerbread man adorned with
            icing. They were not just friends; they were a confectionery family.
          </p>
          <br />
          <p className="font-normal text-2xl">
            Every day was an adventure in Candy Village. They would meet at the
            licorice playground, where the swings were twizzly twizzlers and the
            slides were slick with the most delicious candy syrup. Laughter
            filled the air as they played hide and seek among the giant gumdrop
            bushes and chased each other around the candy cane trees.
          </p>
          <br />
          <p className="font-normal text-2xl">
            Their favorite spot was the candy syrup river that flowed gently
            through the village. They would ride on leaf boats, gliding over the
            smooth, sticky surface, leaving trails of rainbow in their wake. On
            warm days, the chocolate milk pool was the place to be. They would
            dive off the cookie dough diving board and swim in the creamy, rich
            chocolate, floating on wafer rafts and sipping from straw-like
            licorice sticks.
          </p>
          <br />
          <p className="font-normal text-2xl">
            But of all the times of year, Christmas was when Marshmallow shone
            the brightest. It was her birthday, after all, and the entire
            village would be adorned with twinkling lights and garlands made of
            garishly colored wrappers. It was also the time when Marshmallow
            embarked on a special journey to the North Pole to help Santa Claus.
            With her innate marshmallow sweetness, she brought joy to the elves
            and warmth to the frosty North Pole. She helped pack presents,
            adding a sprinkle of marshmallow magic to each one, ensuring every
            child's Christmas was as sweet as the treats from Candy Village.
          </p>
          <br />
          <p className="font-normal text-2xl">
            Santa himself had a fondness for Marshmallow. "You, my dear
            Marshmallow, bring the spirit of Christmas to life," he would say
            with a twinkle in his eye. "Your heart is the sweetest gift of all."
          </p>
          <br />
          <p className="font-normal text-2xl">
            As the years went by, the legend of Marshmallow and her friends grew
            far and wide. Children would dream of visiting Candy Village,
            playing with Marshmallow and her friends, and tasting the
            never-ending sweetness of their world. And soon, the dream was to
            become even more tangible. Marshmallow and her friends were to be
            crafted into toys, little ambassadors of joy, sweetness, and
            friendship for children everywhere.
          </p>
          <br />
          <p className="font-normal text-2xl">
            This is the story of Marshmallow, the Christmas-born marshmallow
            from Candy Village, whose heart was as soft and warm as her fluffy
            exterior. And though she may have been made of sugar and joy, her
            love and kindness were the true gifts that she shared with the
            world.
          </p>
        </div>
      </div>
      <div className="about-utility-container mt-10 w-auto bg-marshmallow-white rounded-[40px] overflow-hidden p-10 lg:w-2/3 shadow">
        <div className="about-utility-title-container items-center border-solid border-black border-b-[1px] flex flex-row justify-between pb-5">
          <div className="about-utility-title uppercase text-3xl lg:text-5xl transition-all duration-500 ease-in-out font-bold">
            utility
          </div>
          <div
            className={`about-utility-image h-12 w-12 bg-marshmallow-blue bg-no-repeat [background-position:50%] [background-size:60%_60%] rounded-[50%] transform ${
              isUtilityRotated ? "rotate-45" : "rotate-0"
            } transition-all duration-500 ease-in-out cursor-pointer`}
            style={{
              backgroundImage: `url(${PlusIcon})`,
            }}
            onClick={toggleUtilityRotation}
          />
        </div>
        <div className="font-normal text-2xl mt-5">
          In the sweet expanse of Web3, $MLOW is more than a token — it's a
          fluffy gathering of joy and dreams! Just imagine us as your friendly
          neighborhood marshmallows, warming up the digital world with our soft
          and supportive community. We're on a whimsical adventure, marshmallow
          pack in hand, aiming to sprinkle a little sugar by welcoming over
          100,000 holders and popping up on over 20 exchanges. Each new
          connection adds a sprinkle of joy, and together, we're crafting a
          delightful story where every moment is worth savoring. So, let's toast
          to the marshmallowy journey ahead — it's going to be sweet!
        </div>
        <div
          className={`${isUtilityCollapsed ? collapsed : expanded} 
            font-normal text-2xl mt-5 [transition:all_2s_ease] border-b border-solid border-black overflow-hidden max-h-[0] pb-0`}
        >
          The journey of $MLOW, while still in its early stages, is poised for
          dynamic growth. The Marshmallow Token is built on a foundation that
          transitions seamlessly across multiple pivotal phases, each designed
          to enhance its role in the Web3 ecosystem.
          <br />
          <br />
          <p className="font-bold">MEME TOKEN LAUNCH</p>
          Starting as a meme token with a 10 billion total supply, $MLOW focuses
          on robust community engagement and reserve allocation for future NFT
          and toy production phases.
          <br />
          <br />
          <p className="font-bold">NFT INTEGRATION</p>
          Transitioning to NFTs, $MLOW will enable token holders to mint and
          purchase unique NFTs, offering them exclusive access and input into
          the NFT designs, thereby strengthening the community's role in the
          project's evolution.
          <br />
          <br />
          <p className="font-bold">DEFI ECOSYSTEM DEVELOPMENT</p>
          With the introduction of Marshmallow Swap and staking mechanisms,
          $MLOW aims to become a key player in the DeFi space, offering more
          utility and integration with various platforms.
          <br />
          <br />
          <p className="font-bold">GAMING & SOCIAL FEATURES</p>
          $MLOW will tap into the gaming world with interactive blockchain-based
          games and social features, increasing engagement and offering new ways
          to earn and spend tokens.
          <br />
          <br />
          <p className="font-bold">PHYSICAL TOY PRODUCTION</p>
          The final transformation sees $MLOW bridging into the physical world
          with Marshmallow-themed toys, merging digital collectible experiences
          with tangible products. Token redemption for toys, loyalty programs,
          and strategic partnerships for production and distribution mark this
          phase.
          <br />
          <br />
          <p className="font-bold">METAVERSE PRESENCE & WALLET SUPPORT</p>
          As $MLOW ventures into the Metaverse, it aims to create immersive
          experiences where users can interact, trade, and showcase their NFTs
          and toys, supported by enhanced wallet functionalities.
          <br />
          <br />
          Integral to $MLOW's growth are its commitments to multi-chain
          integration, community-driven development, and regulatory compliance,
          ensuring a sustainable and expansive presence within the Web3 space.
        </div>
      </div>
      <div className="about-distribution-container items-center flex-col flex lg:items-start lg:flex-row justify-between mt-12">
        <img
          className="about-dist-container-image h-72"
          src={MarshmallowImage}
          alt="Marshmallow"
          style={{ transform: "rotate(-10deg)" }}
        />
        <div className="about-dist-container mt-10 w-auto bg-marshmallow-white rounded-[40px] overflow-hidden p-10 lg:w-2/3 shadow">
          <div className="about-dist-title-container items-center border-solid border-black border-b-[1px] flex flex-row justify-between pb-5">
            <div className="about-dist-title uppercase text-3xl lg:text-5xl transition-all duration-500 ease-in-out font-bold">
              distribution
            </div>
            <div
              className={`about-dist-image h-12 w-12 bg-marshmallow-blue bg-no-repeat [background-position:50%] [background-size:60%_60%] rounded-[50%] transform ${
                isDistributionRotated ? "rotate-45" : "rotate-0"
              } transition-all duration-500 ease-in-out cursor-pointer`}
              style={{
                backgroundImage: `url(${PlusIcon})`,
              }}
              onClick={toggleDistributionRotation}
            />
          </div>
          <div className="font-normal text-2xl mt-5">
            The Marshmallow Token (
            <span className="text-marshmallow-blue font-semibold">$MLOW</span>)
            is strategically distributed to foster a thriving, inclusive
            community while supporting the token's growth and stability.
          </div>
          <div
            className={`${
              isDistributionCollapsed ? collapsed : expanded
            } font-normal text-2xl mt-5 [transition:all_2s_ease] border-b border-solid border-black overflow-hidden max-h-[0] pb-0`}
          >
            <h3 className="text-xl font-bold mb-2">
              Initial Token Distribution
            </h3>
            <p>
              A significant portion of the token allocation is reserved for
              community incentives, ensuring active participation and sustained
              engagement in the project's journey.
            </p>
            <ul className="list-disc pl-5 mt-2">
              <li>Community and Ecosystem: 33.24%</li>
              <li>NFT Phase and Toy Production: 25.77%</li>
              <li>Liquidity Provision: 20.22%</li>
              <li>Development and Marketing: 10.66% (split equally)</li>
            </ul>
            <br />
            <br />
            <h3 className="text-xl font-bold mb-2">
              Vesting and Release Schedule
            </h3>
            <p>
              Vesting is crucial for aligning the long-term interests of the
              team and advisors with the community. It ensures a steady and
              controlled release of tokens into the market.
            </p>
            <ul className="list-disc pl-5 mt-2">
              <li>
                <strong>Team and Advisors:</strong> Tokens are subject to a
                4-year vesting period with a one-year cliff. This means that no
                tokens are released in the first year, followed by a gradual
                release of 25% of their tokens each subsequent year.
              </li>
              <li>
                <strong>Ecosystem Development Fund:</strong> Released over 5
                years to support the project’s growth in line with the roadmap
                phases.
              </li>
            </ul>
            <br />
            <br />
            <h3 className="text-xl font-bold mb-2">
              Phase-Specific Distributions
            </h3>
            <p>
              Each phase of $MLOW's development, from the meme token to NFTs and
              toy production, is designed with the community in mind, ensuring
              that token holders benefit at every stage of the project's growth.
            </p>
            <br />
            <br />
            <h3 className="text-xl font-bold mb-2">
              Governance and Participation
            </h3>
            <p>
              $MLOW is more than a token; it's a community. Token holders are
              not just investors but active participants, shaping the project's
              future through governance and engagement.
            </p>
            <br />
            <br />
            <p className="italic">
              Marshmallow Token is committed to building a token ecosystem where
              each holder feels valued and involved, ensuring the community is
              at the heart of every decision and development.
            </p>
          </div>
        </div>
      </div>
      <div>
        <DistributionDetails />
      </div>
    </main>
  );
}

export default About;
