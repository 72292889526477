import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PlusIcon from "../imgs/plus.png";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const isMobile = screenWidth < 1024;

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const mobileViewClass = isMobile ? "mobile-view" : "normal-view";

  const mainMobileClass = isMobile
    ? "bg-marshmallow-white rounded-3xl flex flex-col p-5 shadow"
    : "items-center flex justify-between w-full h-9";

  const mainTopClass = isMobile
    ? "h-full w-full"
    : "flex flex-[1_1] h-full w-auto justify-start";

  const buttonClass = isMobile
    ? "font-black items-center rounded-none flex text-3xl h-full justify-start p-0 w-full"
    : "border-none rounded-lg cursor-pointer text-sm h-full px-6 uppercase whitespace-nowrap bg-marshmallow-white hover:[box-shadow:_inset_0_-5em_0_0_rgb(211,207,205);] duration-700";

  const rightContainerClass = isMobile
    ? ""
    : "gap-3 justify-end whitespace-nowrap flex flex-[1_1] h-full w-auto";

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [isMenuOpen]);

  return (
    <header className="mx-auto absolute w-full z-10">
      <div className="main-container">
        <div className={mobileViewClass}>
          <div className={mainMobileClass}>
            <div className={mainTopClass}>
              <button
                className={
                  buttonClass + " transition-all duration-100 ease-in-out"
                }
                onClick={isMobile ? handleMenuToggle : handleNavigateHome}
              >
                {isMobile ? (
                  <div className="mobile-title-bar-button-container items-center cursor-pointer flex h-full justify-between w-full">
                    MARSHMALLOW
                    <div className="mobile-expand items-center bg-marshmallow-blue rounded-[50%] flex h-8 justify-center transition-all duration-500 ease-in-out w-8">
                      <div
                        className={` mobile-expand-icon h-4 w-4 transform transition-transform duration-500 ${
                          isMenuOpen ? "rotate-45" : "rotate-0"
                        }`}
                        style={{
                          backgroundImage: `url(${PlusIcon})`,
                          backgroundPosition: "50%",
                          backgroundSize: "100% 100%",
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  "MARSHMALLOW"
                )}
              </button>
            </div>
            <div className={rightContainerClass}>
              {isMobile ? (
                <div
                  className={`mobile-expand-container flex flex-col gap-4 h-auto mt-10 max-h-[300vw] overflow-hidden transition-all duration-500 ease-in-out w-full ${
                    isMenuOpen ? "opened" : "collapsed"
                  }`}
                >
                  <Link
                    className="fade-in fade-in-1 bg-[#ece8e8] font-normal items-center border-none rounded-lg cursor-pointer flex text-2xl h-14 justify-start min-h-[56px] pl-4 pr-6 [text-decoration:none]"
                    to="/"
                    onClick={closeMenu}
                  >
                    HOME
                  </Link>
                  <Link
                    className="fade-in fade-in-2 bg-[#ece8e8] font-normal items-center border-none rounded-lg cursor-pointer flex text-2xl h-14 justify-start min-h-[56px] pl-4 pr-6 [text-decoration:none]"
                    to="/about"
                    onClick={closeMenu}
                  >
                    ABOUT
                  </Link>
                  <Link
                    className="fade-in fade-in-3 bg-[#ece8e8] font-normal items-center border-none rounded-lg cursor-pointer flex text-2xl h-14 justify-start min-h-[56px] pl-4 pr-6 [text-decoration:none]"
                    to="/roadmap"
                    onClick={closeMenu}
                  >
                    ROADMAP
                  </Link>
                  <Link
                    className="fade-in fade-in-4 bg-[#ece8e8] font-normal items-center border-none rounded-lg cursor-pointer flex text-2xl h-14 justify-start min-h-[56px] pl-4 pr-6 [text-decoration:none]"
                    to="/marshmallowpaper"
                    onClick={closeMenu}
                  >
                    MARSHMALLOW PAPER
                  </Link>
                  <Link
                    className="fade-in fade-in-4 bg-[#ece8e8] font-normal items-center border-none rounded-lg cursor-pointer flex text-2xl h-14 justify-start min-h-[56px] pl-4 pr-6 [text-decoration:none]"
                    to="/integrations"
                    onClick={closeMenu}
                  >
                    INTEGRATIONS
                  </Link>
                </div>
              ) : (
                <>
                  <Link
                    className="border-none rounded-lg cursor-pointer text-sm h-full px-6 uppercase whitespace-nowrap bg-marshmallow-white hover:[box-shadow:_inset_0_-5em_0_0_rgb(211,207,205);] duration-700 flex items-center"
                    to="/about"
                  >
                    ABOUT
                  </Link>
                  <Link
                    className="border-none rounded-lg cursor-pointer text-sm h-full px-6 uppercase whitespace-nowrap bg-marshmallow-white hover:[box-shadow:_inset_0_-5em_0_0_rgb(211,207,205);] duration-700 flex items-center"
                    to="/roadmap"
                  >
                    ROADMAP
                  </Link>
                  <Link
                    className="border-none rounded-lg cursor-pointer text-sm h-full px-6 uppercase whitespace-nowrap bg-marshmallow-white hover:[box-shadow:_inset_0_-5em_0_0_rgb(211,207,205);] duration-700 flex items-center"
                    to="/marshmallowpaper"
                  >
                    MARSHMALLOW PAPER
                  </Link>
                  <Link
                    className="border-none rounded-lg cursor-pointer text-sm h-full px-6 uppercase whitespace-nowrap bg-marshmallow-white hover:[box-shadow:_inset_0_-5em_0_0_rgb(211,207,205);] duration-700 flex items-center"
                    to="/integrations"
                  >
                    INTEGRATIONS
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
